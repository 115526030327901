import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CKEditor from "./editor/CKEditor";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { invokeApi } from "../../../../bl_libs/ApiCalling";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  uplaod: {
    marginTop: 10,
    height: 55,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  input: {
    marginLeft: "40%",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function Addpodcast(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [text, settext] = React.useState("");
  const [default_user, setDefaultUsers] = React.useState([]);
  const [selected_user, setSelectedCustomer] = React.useState();
  const [podcast_categroy, setBorcastCategory] = React.useState([]);
  const [podcast_description, setpodcast_des] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [Imagefile, setPodcastImage] = React.useState("");
  const [selected_category, setSelectedCategory] = React.useState([]);
  const [is_add_podcast, set_ispodcast] = React.useState(false);
  const [video, setVideo] = React.useState("");
  const [Savepublish, setSavePublish] = React.useState(false);
  const [podcastType, setPodcastType] = React.useState("audio");
  const [name, set_name] = React.useState([]);
  const [patients_tags, setpatients_tags] = React.useState([]);
  //patient tags
  const handle_patiett_tag_change = (value) => {
    console.log(value, "value");
    let _patTag = [];
    value.map((x, i) => {
      _patTag.push({ name: x.name, user_id: x.user_id });
    });
    setpatients_tags(_patTag);
    console.log(_patTag, "final tags");
  };

  ////audio file
  let audioTune = "";
  const [file, setAudioFile] = React.useState("");
  const [audio, setAudio] = React.useState("");

  // erro flag
  const [errorflag, setflag] = React.useState("");
  const theme = useTheme();
  const handleback = () => {
    history.goBack();
  };
  const onplay_click = () => {
    audioTune.play();
  };
  const handlepodcast_des = (event) => {
    setpodcast_des(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
    console.log(podcast_description);
  };
  const handleChangeInCkEdditor = (text) => {
    console.log(text);
    settext(text);
  };
  const handle_list_catgeory_Change = (event) => {
    setSelectedCategory(event.target.value);
    console.log(event.target.value, "category");
  };
  const fileChangedHandlerForImage = (event) => {
    //const file = event.target.files[0];
    console.log(file);
    // setPodcastImage(URL.createObjectURL(event.target.files[0]));
    setPodcastImage(event.target.files[0]);
  };
  const handleSavePublishChange = (event) => {
    setSavePublish(event.target.value);
    console.log(event.target.value, "save or publish");
  };
  const handlePodcastType = (event) => {
    setPodcastType(event.target.value);
    console.log(event.target.value, "podcast type");
  };

  const fileChangedHandler = (event) => {
    //const file = event.target.files[0];
    console.log(event.target.files[0].name, "this is file");
    setAudio(event.target.files[0]);
    setAudioFile(URL.createObjectURL(event.target.files[0]));
  };
  const getDefaultUser = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/default_customer`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code === 200) {
        const default_user = [];
        res.customer.map((x, i) => {
          if (x.status === "true" || x.status === true) {
            default_user.push(x);
          }
        });
        console.log(default_user, "default_user");
        setDefaultUsers(default_user);
      }
    });
  };
  const getPodcastCategory = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/podcast_category/all_podcast_category`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      const podcastCategory = [];
      if (res.code == 200) {
        for (var c = 0; c < res.podcast_category.length; c++) {
          if (res.podcast_category[c].status == true) {
            podcastCategory.push(res.podcast_category[c]);
          }
        }
        setBorcastCategory(podcastCategory);
      }
    });
  };
  const get_initial_data = async () => {
    await getDefaultUser();
    await getPodcastCategory();
    await get_users();
  };
  const handle_addpodcast = (event) => {
    set_ispodcast(true);
    event.preventDefault();

    if (!audio && podcastType == "audio") {
      setflag("please uplaod audio file");
      set_ispodcast(false);
      return;
    }
    if (!Imagefile) {
      enqueueSnackbar("image is required", { variant: "error" });
      set_ispodcast(false);

      return;
    }
    if (!text) {
      enqueueSnackbar("detailed description is required", { variant: "error" });
      set_ispodcast(false);

      return;
    }

    let formData = new FormData(); //formdata object
    console.log(file, "this is file");
    if (audio) {
      formData.append("audio_file", audio);
    }
    formData.append("video", video);
    formData.append("podcast_type", podcastType);
    formData.append("user_id", selected_user._id);
    formData.append("prodcast_category_id", selected_category);
    formData.append("status", status);
    formData.append("short_description", podcast_description);
    formData.append("detailed_description", text);
    formData.append("save_or_publish", Savepublish);
    var Patient_filtered = patients_tags.filter(function (el) {
      return el.name != null && el.name !== "" && el.name.trim() !== "";
    });
    console.log(Patient_filtered, "pat tags");
    formData.append("patients_tags", JSON.stringify(Patient_filtered));

    if (Imagefile) {
      formData.append("image", Imagefile);
    }

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/podcast/add_podcast",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE of");
      set_ispodcast(false);
      if (res && res.code === 200) {
        props.history.push("/postit/podcast");
        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        setflag(res.message);
        enqueueSnackbar("error while adding podcast", { variant: "error" });
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
      }
    });
  };
  useEffect(() => {
    audioTune = new Audio(file);
    audioTune.load();
    get_initial_data();
  }, []);
  return (
    <>
      {is_add_podcast === true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="xs">
          <ArrowBackIcon
            className={classes.backarrow}
            onClick={handleback}
          ></ArrowBackIcon>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Add podcast
            </Typography>
            <form className={classes.form} onSubmit={handle_addpodcast}>
              {errorflag && (
                <Alert severity="error" style={{ marginBottom: 20 }}>
                  {errorflag}
                </Alert>
              )}
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Autocomplete
                    id="users"
                    disableClearable
                    value={selected_user}
                    onChange={(event, value) => {
                      setSelectedCustomer(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={default_user}
                    className={classes.patient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose user"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onClick={() => setStatus(!status)}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <TextField
                variant="outlined"
                margin="normal"
                multiline
                rows={2}
                rowsMax={4}
                required
                fullWidth
                onChange={handlepodcast_des}
                label="Short description"
                name="podcast-description"
                value={podcast_description}
              />
              {/* Patient tags */}
              <Autocomplete
                multiple
                id="tags-filled"
                options={name}
                style={{ paddingBottom: 25 }}
                getOptionLabel={(name) => name.name}
                onChange={(event, value) => handle_patiett_tag_change(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Patients"
                    placeholder="Enter new Patient"
                  />
                )}
              />

              <CKEditor content={text} setContent={handleChangeInCkEdditor} />
              {Imagefile ? (
                <img
                  src={URL.createObjectURL(Imagefile)}
                  className={classes.blog_img}
                ></img>
              ) : null}
              <Button
                fullWidth
                variant="outlined"
                component="label"
                className={classes.uplaod}
                startIcon={<CloudUploadIcon />}
                color="primary"
              >
                <input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  hidden
                  onChange={fileChangedHandlerForImage}
                ></input>
                Upload Podcast image (600X500)
              </Button>
              {podcastType == "video" ? (
                <TextField
                  variant="outlined"
                  margin="normal"
                  multiline
                  value={video}
                  onChange={(e) => setVideo(e.target.value)}
                  rows={3}
                  rowsMax={4}
                  fullWidth
                  label="Video Embed Code"
                  name="description"
                />
              ) : null}
              {file ? (
                <>
                  {podcastType == "audio" ? (
                    <Box>
                      <figure>
                        <audio
                          controls
                          src={file}
                          style={{ width: "100%", borderColor: "white" }}
                        >
                          Your browser does not support the
                          <code>audio</code> element.
                        </audio>
                      </figure>
                    </Box>
                  ) : null}
                </>
              ) : null}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Audio or Video
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={podcastType}
                      fullWidth
                      onChange={handlePodcastType}
                      label="Save or Publish"
                    >
                      <MenuItem value={"audio"}>Audio</MenuItem>
                      <MenuItem value={"video"}>Video</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                    className={classes.uplaod}
                    startIcon={<CloudUploadIcon />}
                    disabled={podcastType == "audio" ? false : true}
                    color="primary"
                  >
                    <input
                      type="file"
                      accept=".mp3,audio"
                      hidden
                      onChange={fileChangedHandler}
                    ></input>
                    Upload Audio{" "}
                  </Button>
                </Grid>
              </Grid>
              {/* podcast category */}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-mutiple-name-label">
                      Podcast Category
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selected_category}
                      required
                      fullWidth
                      onChange={handle_list_catgeory_Change}
                      label="podcast category"
                      input={
                        <OutlinedInput
                          id="outlined-basic"
                          label="podcast Category"
                          variant="outlined"
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Category</em>
                      </MenuItem>
                      {podcast_categroy.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Save or Publish
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={Savepublish}
                      fullWidth
                      onChange={handleSavePublishChange}
                      label="Save or Publish"
                    >
                      <MenuItem value={false}>Save</MenuItem>
                      <MenuItem value={true}>Publish</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Add podcast
              </Button>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
