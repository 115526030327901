import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CKEditor from "./editor/CKEditor";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Country } from "./countries";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
const useStyles = makeStyles((theme) => ({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#d8d433",
    color: "white",
  },
  input: {
    marginLeft: "40%",
  },
}));

export default function Addauthor(props) {
  const classes = useStyles();
  const history = useHistory();
  if (!props.location.author) {
    props.location.author = {};
  }
  const [author_date, set_author_date] = React.useState(props.location.author);
  const [user_profile_image, set_user_profile_image] = React.useState(
    author_date.image
  );
  const [profile_image, set_profile_image] = React.useState("");
  const [file, set_profile_file] = React.useState("");
  const [text, settext] = React.useState(author_date.detailed_description);
  const [name, setName] = React.useState(author_date.name);
  const [gender, setGender] = React.useState(author_date.gender);
  const [country, setCountry] = React.useState(author_date.nationality);
  const [status, setStatus] = React.useState(author_date.status);
  const [short_description, set_author_description] = React.useState(
    author_date.short_description
  );
  const [errorflag, setflag] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(author_date.date_of_birth)
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const theme = useTheme();
  const handleback = () => {
    history.goBack();
  };

  const handleChangeInCkEdditor = (text) => {
    settext(text);
  };
  const handleName = (event) => {
    setName(event.target.value);
  };

  const handle_profile = (event) => {
    set_profile_image(URL.createObjectURL(event.target.files[0]));
    set_profile_file(event.target.files[0]);
  };
  const handleAddAuthor = (event) => {
    event.preventDefault();
    let formData = new FormData(); //formdata object
    formData.append("name", name);
    if (file) {
      formData.append("image", file);
    }
    formData.append("nationality", country);
    if (!gender) {
      setflag("Please select your Gender");
      return;
    }
    formData.append("gender", gender);
    formData.append("date_of_birth", selectedDate);
    formData.append("short_description", short_description);
    formData.append("detailed_description", text);
    formData.append("status", status);

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/blog_author/edit_blog_author/${author_date._id}`,
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        props.history.push("/postit/blog-authors");
      } else {
        setflag(res.message);
      }
    });
  };
  useEffect(() => {
    if (JSON.stringify(props.location.author) == JSON.stringify({})) {
      props.history.push("/postit/blog-authors");
    }
  }, []);
  return (
    <Container component="main" maxWidth="xs">
      <ArrowBackIcon
        className={classes.backarrow}
        onClick={handleback}
      ></ArrowBackIcon>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Edit blog author
        </Typography>
        <form className={classes.form} onSubmit={handleAddAuthor}>
          {errorflag && <Alert severity="error">{errorflag}</Alert>}
          <div className={classes.input}>
            <input
              accept="image/x-png,image/jpeg"
              hidden
              id="contained-button-file"
              multiple
              type="file"
              onChange={handle_profile}
            />
            <label htmlFor="contained-button-file">
              <Avatar
                alt="Remy Sharp"
                className={classes.avtr}
                src={
                  profile_image == ""
                    ? image_url + user_profile_image
                    : profile_image
                }
              >
                <AddAPhotoIcon />
              </Avatar>
            </label>
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Name"
            name="name"
            value={name}
            onChange={handleName}
          />
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Autocomplete
                id="country-select-demo"
                style={{ width: 300 }}
                options={Country}
                defaultValue={{ name: country, code: "AR" }}
                classes={{
                  option: classes.option,
                }}
                onChange={(event, value) => {
                  console.log(value);
                  setCountry(value.name);
                }} // prints the selected value
                fullWidth
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.name} ({option.code})
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 16, width: 393 }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset" style={{ marginTop: 20 }}>
                <FormLabel component="legend">Gender</FormLabel>

                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue={gender}
                >
                  <FormControlLabel
                    onChange={(e) => {
                      setGender(e.target.value);
                      console.log(gender, "this is gender");
                    }}
                    value="male"
                    control={<Radio color="primary" />}
                    label="Male"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    onChange={(e) => {
                      setGender(e.target.value);
                      console.log(gender, "this is gender");
                    }}
                    value="female"
                    control={<Radio color="primary" />}
                    label="Female"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date of birth"
                  format="MM-dd-yyyy"
                  inputVariant="outlined"
                  fullWidth
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset" style={{ marginTop: 25 }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={status}
                      onClick={() => setStatus(!status)}
                    />
                  }
                  label="Status"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            multiline
            rows={2}
            rowsMax={4}
            fullWidth
            label="Short description"
            name="Author-description"
            value={short_description}
            onChange={(e) => set_author_description(e.target.value)}
          />

          <CKEditor content={text} setContent={handleChangeInCkEdditor} />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Edit author
          </Button>
        </form>
      </div>
    </Container>
  );
}
