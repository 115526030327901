import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { Listusers, Adduser, Edituser } from "../users/users";
import dashboard from "../dashboard/dashboard";
import Updatepassword from "../updatepassword/Updatepassword";
import { Listtblogs, Addblog, Editblogs } from "../blogs/index";
import { Listcategory, Addcategory, Editcategory } from "../categories/index";
import { Listauthors, Addauthor, Editauthor } from "../Blogauthors/index";
import { Listpodcast, Addpodcast, Editpodcast } from "../podcast/index";
import {
  ListPodcastCategory,
  Add_podcast_category,
  Edit_podcast_Catgeory,
} from "../Podcastcategory/index";
import { Listphotos, AddPhoto, Editphoto, Comments } from "../photos/index";
import { ListVideo, AddVideo, Editvideo, VideoComments } from "../video/index";
import {
  Liststory,
  Addstory,
  Editstory,
  Expirestory,
  Inactivestory,
} from "../stories/index";
import { Setting } from "../setting/index";
import { Listnews, Addnews, Editnews } from "../News/index";
import { Chat } from "../chat/index";
import { Listsecrteries, Addsecrtery, Editsecrtery } from "../secrteries/index";
import {
  Listappointment,
  Addappointment,
  Editappointment,
  AppointmentDetail,
} from "../appointments/index";
import { List_patients, Add_patient, Edit_patient } from "../patients/index";
import { List_clinic, Add_clinic, Edit_clinic } from "../clinic/index";
import { Listadmin, Addadmin, Editadmin } from "../admins/index";

function HomePage() {
  return (
    <>
      <Switch>
        <Redirect exact from="/postit" to="/postit/dashboard" />
        <Route path="/postit/chat" component={Chat} />
        <Route path="/postit/admin" component={Listadmin} />
        <Route path="/postit/add_admin" component={Addadmin} />
        <Route path="/postit/edit_admin" component={Editadmin} />
        <Route path="/postit/users" component={Listusers} />
        <Route path="/postit/dashboard" component={dashboard} />
        <Route path="/postit/adduser" component={Adduser} />
        <Route path="/postit/edituser" component={Edituser} />
        <Route path="/postit/updatepassword" component={Updatepassword} />
        <Route path="/postit/blogs" component={Listtblogs} />
        <Route path="/postit/addblog" component={Addblog} />
        <Route path="/postit/editblog/:blogid" component={Editblogs} />
        <Route path="/postit/blogCategories" component={Listcategory} />
        <Route path="/postit/addBlogCategories" component={Addcategory} />
        <Route path="/postit/editBlogCategories" component={Editcategory} />
        <Route path="/postit/blog-authors" component={Listauthors} />
        <Route path="/postit/add-Blog-author" component={Addauthor} />
        <Route path="/postit/edit-Blog-author" component={Editauthor} />
        <Route path="/postit/podcast" component={Listpodcast} />
        <Route path="/postit/add-podcast" component={Addpodcast} />
        <Route path="/postit/edit-podcast" component={Editpodcast} />
        <Route path="/postit/setting" component={Setting} />
        <Route
          path="/postit/podcast-category"
          component={ListPodcastCategory}
        />
        <Route
          path="/postit/add-podcast-category"
          component={Add_podcast_category}
        />
        <Route
          path="/postit/edit-podcast-category"
          component={Edit_podcast_Catgeory}
        />
        <Route path="/postit/photos" component={Listphotos} />
        <Route path="/postit/add-photo" component={AddPhoto} />
        <Route path="/postit/edit-photo" component={Editphoto} />
        <Route path="/postit/photo-comments/:photoid" component={Comments} />
        <Route path="/postit/videos" component={ListVideo} />
        <Route path="/postit/add-video" component={AddVideo} />
        <Route
          path="/postit/video-comments/:videoid"
          component={VideoComments}
        />
        <Route path="/postit/edit-video" component={Editvideo} />
        <Route path="/postit/expire_story" component={Expirestory} />
        <Route path="/postit/inactive_story" component={Inactivestory} />
        <Route path="/postit/story" component={Liststory} />
        <Route path="/postit/add-story" component={Addstory} />
        <Route path="/postit/edit-story/:id" component={Editstory} />
        <Route path="/postit/list-news" component={Listnews} />
        <Route path="/postit/add-news" component={Addnews} />
        <Route path="/postit/edit-news" component={Editnews} />
        <Route path="/postit/secrtery" component={Listsecrteries} />

        <Route path="/postit/add-secrtery" component={Addsecrtery} />
        <Route path="/postit/edit-secrtery" component={Editsecrtery} />
        <Route path="/postit/appointment" component={Listappointment} />
        <Route path="/postit/add-appointment" component={Addappointment} />
        <Route path="/postit/edit-appointment" component={Editappointment} />
        <Route
          path="/postit/detail-appoitment/:id"
          component={AppointmentDetail}
        />

        <Route path="/postit/patient" component={List_patients} />
        <Route path="/postit/add_patient" component={Add_patient} />
        <Route path="/postit/edit_patient" component={Edit_patient} />

        <Route path="/postit/clinic" component={List_clinic} />
        <Route path="/postit/add_clinic" component={Add_clinic} />
        <Route path="/postit/edit_clinic" component={Edit_clinic} />
      </Switch>
    </>
  );
}

export default withRouter(HomePage);
