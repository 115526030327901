import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import CKEditor from "./editor/CKEditor";
import Alert from "@material-ui/lab/Alert";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { invokeApi } from "../../../../bl_libs/ApiCalling";
import { useSnackbar } from "notistack";
var moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 600, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
  },
  uplaod: {
    marginTop: 20,
  },
  blog_img: {
    marginTop: 10,
    width: 600,
    height: 300,
  },
  formControl: {
    marginTop: 10,
  },
  backarrow: {
    marginInlineStart: "-100px",
    cursor: "pointer",
    marginTop: "2%",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, blog_category, theme) {
  return {
    fontWeight:
      blog_category.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function Addblog(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [names, setnames] = React.useState([]);
  const [authors, setauthors] = React.useState([]);
  const [blog_category, setblogcategory] = React.useState([]);
  const [file, setBlogImage] = React.useState("");
  const [text, settext] = React.useState("");
  const [blog_title, setblog_title] = React.useState("");
  const [blog_description, setblog_des] = React.useState("");
  const [status, setStatus] = React.useState(true);
  const [blog_add, setAddBlog] = React.useState(false);
  const [selectedblogger, setselectedblogger] = React.useState([]);
  const [errorflag, setflag] = React.useState("");
  const [Savepublish, setSavePublish] = React.useState(false);
  const [name, set_name] = React.useState([]);
  const [patients_tags, setpatients_tags] = React.useState([]);
  // const [default_user, setDefaultUsers] = React.useState([]);
  // const [selected_user, setSelectedCustomer] = React.useState();

  const theme = useTheme();
  const handleChangeblogger = (event) => {
    setselectedblogger(event.target.value);
    console.log(event.target.value, "selected value");
  };
  const handleblog_category = (event) => {
    console.log(event.target.value);
    setblogcategory(event.target.value);
  };
  //patient tags
  const handle_patiett_tag_change = (value) => {
    console.log(value, "value");
    let _patTag = [];
    value.map((x, i) => {
      _patTag.push({ name: x.name, user_id: x.user_id });
    });
    setpatients_tags(_patTag);
    console.log(_patTag, "final tags");
  };

  const handleback = () => {
    history.goBack();
  };
  const handleblog_title = (event) => {
    setblog_title(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
    console.log(blog_title);
  };
  const handleblog_des = (event) => {
    setblog_des(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
    console.log(blog_description);
  };
  const fileChangedHandler = (event) => {
    //const file = event.target.files[0];
    console.log(file);
    // setBlogImage(URL.createObjectURL(event.target.files[0]));
    setBlogImage(event.target.files[0]);
  };

  const handleChangeInCkEdditor = (text) => {
    console.log(text, "detailed");
    settext(text);
  };
  // save and publish

  const handleSavePublishChange = (event) => {
    setSavePublish(event.target.value);
    console.log(event.target.value, "save or publish");
  };
  const handleAddBlog = (event) => {
    event.preventDefault();
    if (!text) {
      enqueueSnackbar("detailed description is required", { variant: "error" });
      return;
    }
    if (!file) {
      enqueueSnackbar("blog image is required", { variant: "error" });
      return;
    }
    setAddBlog(true);
    let formData = new FormData(); //formdata object
    formData.append("title", blog_title);
    // formData.append("user_id", selected_user.user_id);
    formData.append("status", status);
    formData.append("short_description", blog_description);
    formData.append("detailed_description", text);
    if (file) {
      formData.append("image", file);
    }
    formData.append("blog_category", blog_category);
    formData.append("blog_author", selectedblogger);
    formData.append("save_or_publish", Savepublish);
    formData.append("publish_date_time", moment());
    var Patient_filtered = patients_tags.filter(function (el) {
      return el.name != null && el.name !== "" && el.name.trim() !== "";
    });
    console.log(Patient_filtered, "pat tags");
    formData.append("patients_tags", JSON.stringify(Patient_filtered));

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/blog/add_blog",
      method: "POST",
      postData: formData,
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      if (res.code == 200) {
        props.history.push("/postit/blogs");
        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        setAddBlog(false);
        setflag(res.message);
        enqueueSnackbar(res.message, { variant: "error" });
      }
    });
  };
  const get_blogCategory = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/blog_category/all_blog_category",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      const blogCategories = [];
      if (res.code == 200) {
        for (var c = 0; c < res.blog_category.length; c++) {
          if (res.blog_category[c].status !== false) {
            blogCategories.push(res.blog_category[c]);
          }
        }
        setnames(blogCategories);
      } else {
        setflag(res.message);
      }
    });
  };
  const get_authors = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/blog_author/all_blog_author",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      const Authors = [];
      if (res.code == 200) {
        for (var a = 0; a < res.blog_author.length; a++) {
          if (res.blog_author[a].status == true) {
            Authors.push(res.blog_author[a]);
          }
        }
        setauthors(Authors);
      } else {
        setflag(res.message);
      }
    });
  };
  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/customer/list_all_customers",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        let patients = [];
        let customer = res.customer;
        customer.map((x, i) => {
          if (x.is_patient == true) {
            patients.push(x);
          }
        });
        set_name(patients);
      }
    });
  };
  // const getDefaultUser = async () => {
  //   const header = {
  //     "x-sh-auth": localStorage.getItem("token"),
  //   };
  //   let requestObj = {
  //     path: `/api/customer/default_customer`,
  //     method: "GET",
  //     headers: header,
  //   };
  //   invokeApi(requestObj).then((res) => {
  //     console.log(res, "DEFAULT CUSTOMER");
  //     if (res.code == 200) {
  //       setDefaultUsers(res.customer);
  //     }
  //   });
  // };
  const get_blogCategory_authors_list = async () => {
    await get_blogCategory();
    await get_authors();
    await get_users();
    //await getDefaultUser();
  };
  useEffect(() => {
    get_blogCategory_authors_list();
  }, []);
  return (
    <>
      {blog_add == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="xs">
          <ArrowBackIcon
            className={classes.backarrow}
            onClick={handleback}
          ></ArrowBackIcon>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Add Blog
            </Typography>
            <form className={classes.form} onSubmit={handleAddBlog}>
              {errorflag && <Alert severity="error">{errorflag}</Alert>}
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                  <Autocomplete
                    id="users"
                    disableClearable
                    value={selected_user}
                    onChange={(event, value) => {
                      setSelectedCustomer(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={default_user}
                    className={classes.patient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose user"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Blog title"
                    name="title"
                    onChange={handleblog_title}
                    value={blog_title}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormControl component="fieldset" style={{ marginTop: 35 }}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onClick={() => setStatus(!status)}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <TextField
                required
                variant="outlined"
                margin="normal"
                multiline
                rows={2}
                rowsMax={4}
                fullWidth
                onChange={handleblog_des}
                label="Short Description"
                name="title"
                value={blog_description}
              />
              {/* Patient tags */}
              <Autocomplete
                multiple
                id="tags-filled"
                options={name}
                style={{ paddingBottom: 25 }}
                getOptionLabel={(name) => name.name}
                onChange={(event, value) => handle_patiett_tag_change(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    </>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    multiline
                    style={{ marginTop: 20 }}
                    rows={1}
                    rowsMax={4}
                    label="Patients"
                    placeholder="Enter new Patient"
                  />
                )}
              />

              <CKEditor content={text} setContent={handleChangeInCkEdditor} />
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  className={classes.blog_img}
                ></img>
              ) : null}

              <Button
                fullWidth
                variant="outlined"
                component="label"
                className={classes.uplaod}
                startIcon={<CloudUploadIcon />}
                color="primary"
              >
                <input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  hidden
                  onChange={fileChangedHandler}
                ></input>
                Upload Blog image (600 X 500)
              </Button>

              {/* <CKEditor content={text} setContent={handleChangeInCkEdditor} /> */}

              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-mutiple-name-label">
                  Blog Category
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={blog_category}
                  required
                  fullWidth
                  onChange={handleblog_category}
                  label="Blog category"
                  input={
                    <OutlinedInput
                      id="outlined-basic"
                      label="Blog Category"
                      variant="outlined"
                    />
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value="">
                    <em>Blog Category</em>
                  </MenuItem>
                  {names.map((name) => (
                    <MenuItem
                      key={name._id}
                      value={name._id}
                      style={getStyles(name, blog_category, theme)}
                    >
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Author list */}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-mutiple-name-label">
                      Authors
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedblogger}
                      required
                      fullWidth
                      onChange={handleChangeblogger}
                      label="Authors"
                      input={
                        <OutlinedInput
                          id="outlined-basic"
                          label="Authors"
                          variant="outlined"
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Author</em>
                      </MenuItem>
                      {authors.map((name) => (
                        <MenuItem
                          key={name._id}
                          value={name._id}
                          style={getStyles(name, blog_category, theme)}
                        >
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* save and publish */}
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Save or Publish
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={Savepublish}
                      fullWidth
                      onChange={handleSavePublishChange}
                      label="Save or Publish"
                    >
                      <MenuItem value={false}>Save</MenuItem>
                      <MenuItem value={true}>Publish</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Add blog
              </Button>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
