import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import { invokeApi, image_url } from "../../../../bl_libs/ApiCalling";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import default_img from "../../../../assets/images/default_img.jpg";
import { enqueueSnackbar } from "notistack";
///-------dialog-transition-------///
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

export default function ListPodcastCategory(props) {
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, settotalpage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [search_found, setSearchFound] = React.useState(true);
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> Title</span>,
      field: "title",
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Image</span>,
      render: (rowData) => (
        <img
          src={rowData.icon == "" ? default_img : image_url + rowData.icon}
          style={{ width: 50, height: 50 }}
        ></img>
      ),
      sorting: false,
    },

    {
      title: <span className={classes.hed}> Status</span>,
      render: (rowData) => (
        <div style={{ width: 100 }}>
          {rowData.status == true ? (
            <>
              <Radio
                style={{ float: "left" }}
                checked={rowData.status}
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>Active</p>
            </>
          ) : (
            <>
              <Radio
                style={{ float: "left" }}
                checked={true}
                disabled
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>In Active</p>
            </>
          )}
        </div>
      ),
      sorting: false,
    },
  ]);

  const hanldeSearchChange = (e) => {
    setSearch(e.target.value);
    setData([]);
    setSearchFound(true);
    //setisdata(false);
    console.log(e.target.value, "this is search");
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    const data = {
      data: e.target.value,
    };
    const params = {
      page: page,
      limit: rowsPerPage,
    };
    let requestObj = {
      path: `/api/podcast_category/search_podcast_category`,
      method: "POST",
      headers: header,
      postData: data,
      queryParams: params,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        console.log(res.podcast_category, "row per pages");
        setData(res.podcast_category.podcast_category);
        setusers(true);
        settotalpage(res.podcast_category.total_pages);
        if (res.podcast_category.podcast_category.length === 0) {
          setSearchFound(false);
        } else {
          setSearchFound(true);
        }
      }
    });
  };

  const Addcategory = () => {
    props.history.push("/postit/add-podcast-category");
  };
  const handleClickOpen = (rowdata) => {
    console.log(rowdata, "rowrow data");

    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };
  const deletecategories = () => {
    setOpen(false);
    //setusers(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    console.log(selectedrow._id, "idididdi");
    let requestObj = {
      path: `/api/podcast_category/delete_podcast_category/${selectedrow._id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      //setusers(true);
      if (res.code == 200) {
        setPage(0);
        get_categories(page);
        enqueueSnackbar("Category deleted successfully.", {
          variant: "success",
        });
      } else {
      }
    });
  };
  const get_categories = async (_page) => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    console.log(_page, "page");
    let requestObj = {
      path: "/api/podcast_category/list_podcast_category",
      method: "GET",
      queryParams: { page: _page, limit: rowsPerPage },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setData(res.podcast_category.podcast_category);
        settotalpage(res.podcast_category.total_pages);
      } else {
      }
    });
  };
  useEffect(() => {
    if (search == "") {
      get_categories(page);
    } else {
      hanldeSearchChange({ target: { value: search } });
    }
  }, [rowsPerPage, page]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <Button
            onClick={Addcategory}
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Add podcast category
          </Button>
          <MaterialTable
            tableRef={tableRef}
            style={{ marginTop: "50px" }}
            title="Podcast category"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            components={{
              Pagination: (props) => (
                <TablePagination
                  component="div"
                  count={totalPage}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ),
              Toolbar: (props) => (
                <div>
                  <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                      <h3>Podcast Categories</h3>
                    </Box>
                    <Box p={1}>
                      <TextField
                        id="input-with-icon-textfield"
                        value={search}
                        focused
                        autoFocus={true}
                        onChange={hanldeSearchChange}
                        //label="Search"
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ float: "right" }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </div>
              ),
            }}
            data={data}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {search_found == false ? (
                      <h5>No data found to display</h5>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                ),
              },
            }}
            actions={[
              {
                icon: () => <DeleteIcon />,
                tooltip: "Delete catgeory",
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                },
              },
              {
                icon: () => <EditIcon />,
                tooltip: "Edit category",
                onClick: (event, rowData) => {
                  props.history.push({
                    pathname: "/postit/edit-podcast-category",
                    podcast_data: rowData,
                  });
                },
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              search: true,
              pageSize: rowsPerPage,
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#DAD651",
                color: "white",
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete this podcast category ?`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deletecategories} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
