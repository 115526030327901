import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import { invokeApi, image_url } from "../../../bl_libs/ApiCalling";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import default_img from "../../../assets/images/default_img.jpg";
import { useSnackbar } from "notistack";
///-------dialog-transition-------///
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

export default function List_clinic(props) {
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, settotalpage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [search, setSearch] = React.useState("");
  const [search_found, setSearchFound] = React.useState(true);

  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}>Clinic Name</span>,
      field: "clinic_name",
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Secrtery Name</span>,
      field: "doctor_name",
      sorting: false,
    },

    {
      title: <span className={classes.hed}> Status</span>,
      render: (rowData) => (
        <div style={{ width: 100 }}>
          {rowData.status === true ? (
            <>
              <Radio
                style={{ float: "left" }}
                checked={rowData.status}
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>Active</p>
            </>
          ) : (
            <>
              <Radio
                style={{ float: "left" }}
                checked={true}
                disabled
                color="primary"
                name="Radio"
              />
              <p style={{ paddingTop: "10px" }}>In Active</p>
            </>
          )}
        </div>
      ),
      sorting: false,
    },
  ]);

  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setPage(newPage);
  };
  const hanldeSearchChange = (e) => {
    setSearch(e.target.value);
    setData([]);
    setSearchFound(true);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    const data = {
      name: e.target.value,
    };

    let requestObj = {
      path: `/api/secretary/search_secrtery`,
      method: "POST",
      headers: header,
      postData: data,
    };
    // invokeApi(requestObj).then((res) => {
    //   console.log(res, "RESPONSE");
    //   if (res.code == 200) {
    //     setData(res.secrtery);
    //     if (res.secrtery.length == 0) {
    //       setSearchFound(false);
    //     } else {
    //       setSearchFound(true);
    //     }
    //   }
    // });
  };
  const handleChangeRowsPerPage = (event) => {
    setData([]);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };
  const Deletecinic = () => {
    setOpen(false);
    setusers(false);
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/clinic/delete_clinic/${selectedrow._id}`,
      method: "DELETE",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        if (search == "") {
          get_clinic(page);
        } else {
          hanldeSearchChange({ target: { value: search } });
        }
        enqueueSnackbar("Clinic deleted successfully!", { variant: "success" });
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    });
  };
  const get_clinic = async (_page) => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/clinic/list_clinic",
      method: "GET",
      queryParams: { page: _page, limit: rowsPerPage },
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setData(res.clinic.clinic);
        settotalpage(res.clinic.total_pages);
        setusers(true);
        if (res.clinic.clinic.length == 0) {
          setSearchFound(false);
        }
      } else {
        setusers(true);
      }
    });
  };
  useEffect(() => {
    if (search == "") {
      get_clinic(page);
    } else {
      hanldeSearchChange({ target: { value: search } });
    }
  }, [rowsPerPage, page]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <Button
            onClick={() => {
              props.history.push("/postit/add_clinic");
            }}
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Add Clinic
          </Button>
          <MaterialTable
            tableRef={tableRef}
            style={{ marginTop: "50px" }}
            title="Clinics"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            components={{
              Pagination: (props) => (
                <TablePagination
                  component="div"
                  count={totalPage}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ),
              // Toolbar: (props) => (
              //   <div>
              //     <Box display="flex" p={1}>
              //       <Box p={1} flexGrow={1}>
              //         <h3>Clinics</h3>
              //       </Box>
              //       <Box p={1}>
              //         <TextField
              //           id="input-with-icon-textfield"
              //           value={search}
              //           focused
              //           autoFocus={true}
              //           onChange={hanldeSearchChange}
              //           placeholder="Search"
              //           InputProps={{
              //             startAdornment: (
              //               <InputAdornment
              //                 position="start"
              //                 style={{ float: "right" }}
              //               >
              //                 <SearchIcon />
              //               </InputAdornment>
              //             ),
              //         }}
              //       />
              //     </Box>
              //   </Box>
              // </div>
              //),
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {search_found == false ? (
                      <h5>No data found to display</h5>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            actions={[
              {
                icon: () => <DeleteIcon />,
                tooltip: "Delete Clinic",
                onClick: (event, rowData) => handleClickOpen(rowData),
              },
              {
                icon: () => <EditIcon />,
                tooltip: "Edit Patient",
                onClick: (event, rowData) =>
                  props.history.push({
                    pathname: "/postit/edit_clinic",
                    clinic_data: rowData,
                  }),
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              search: true,
              pageSize: rowsPerPage,
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#DAD651",
                color: "white",
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete this Clinic?`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={Deletecinic} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
